import L from "leaflet"
import { DEFAULT_MAP_CENTER, LAYER_PROVIDERS_URL, MAP_PROVIDER as MP, SUBDOMAINS, ZOOM_SETTINGS } from "../mapConstantsInfo"

let baseMaps
let hybrid
let osm
let roadMap
let satellite
let ZOOM_MAX_OSM = 19

export function tilesProviders(map, provider) {
    hybrid = L.tileLayer(LAYER_PROVIDERS_URL.hybrid, {
        maxZoom: ZOOM_SETTINGS.zoomMax,
        subdomains: SUBDOMAINS
    })
    osm = L.tileLayer(LAYER_PROVIDERS_URL.osm, {
        maxZoom: ZOOM_MAX_OSM,
        attribution: "© OpenStreetMap"
    })
    roadMap = L.tileLayer(LAYER_PROVIDERS_URL.roadMap, {
        maxZoom: ZOOM_SETTINGS.zoomMax,
        subdomains: SUBDOMAINS
    })
    satellite = L.tileLayer(LAYER_PROVIDERS_URL.satellite, {
        maxZoom: ZOOM_SETTINGS.zoomMax,
        subdomains: SUBDOMAINS
    })
    baseMaps = {
        [MP.hybrid]: hybrid,
        [MP.osm]: osm,
        [MP.roadMap]: roadMap,
        [MP.satellite]: satellite
    }
    L.control.layers(baseMaps).addTo(map)
    let currentLayer = baseMaps[provider].addTo(map)
    map.setView(DEFAULT_MAP_CENTER, ZOOM_SETTINGS.zoomLevel)
    return {currentLayer, baseMaps}
}
export function getBasePoint(points) {
    return points.find((p) => p.pointType === "basepoint")
}