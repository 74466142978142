<template>
  <div>
    <a-layout>
      <a-layout-header v-if="loggedIn">
        <div class="logo">
          <router-link :to="{ path: '/' }">
            <img :src="imagePath" class="logo" />
          </router-link>
        </div>
        <header-menu />
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          v-if="loggedIn"
          breakpoint="lg"
          collapsed-width="0"
          :collapsed="menuCollapsed"
          collapsible
          :trigger="null"
          @breakpoint="onBreakpoint"
          :width="240"
          :collapsedWidth="80"
        >
          <left-menu
            :collapsed="menuCollapsed"
            @collapse-btn-clicked="toggleMenuCollapsed"
          />
        </a-layout-sider>
        <a-layout-content>
          <http-error v-if="loggedIn" />
          <div class="content-container">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
const image = require.context("./assets", true);

import Vue from "vue";
import { Layout } from "ant-design-vue";
import LeftMenu from "./components/LeftMenu";
import HeaderMenu from "./components/HeaderMenu";
import HttpError from "./components/ErrorManager/HttpError";

Vue.use(Layout);

export default {
  name: "app",
  computed: {
    loggedIn() {
      return this.$store.getters.getLoginStatus();
    },
    menuCollapsed() {
      return this.$store.getters.getUserPreferences().menuCollapsed
    },
    langage() {
      return this.$store.getters.getUserPreferences().langage
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("setCurrentUser")
      if (this.$store.state.preferences.lastFetch === null)
        this.$store.dispatch("fetchUserPreferences");
    }
  },
  updated: function () {
  this.$nextTick(function () {
    this.$root.$i18n.locale = this.langage;
    })
  },
  data() {
    return {
      imagePath: image("./logo_svg_light.svg")
    };
  },
  methods: {
    onBreakpoint(broken) {
      if(broken) {
        this.menuCollapsed = true;
      }
    },
    toggleMenuCollapsed() {
      this.$store.dispatch("setMenuCollapsedPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
        user: {...this.$store.getters.getCurrentUser()},
        menuCollapsed: !this.menuCollapsed,
      });
    },
  },
  components: {
    HeaderMenu,
    LeftMenu,
    HttpError,
  },
};
</script>

<style>
#app {
  font: normal 40px "Lato", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #ffffff;
}

.logo {
  float: left;
  width: 225px;
  height: 60px;
}

.ant-layout {
  background-color: #ffffff;
}

.ant-layout-content {
  margin-top: 1rem;
  height: auto;
  margin-left: 2.5%
}

.ant-layout-sider-children {
  background-color: #ffffff;
  width: 300px;
}

.ant-layout-header {
  height: 60px;
  background: #124c5a;
  width: 100%;
}

.btn {
  display: inline-block;
  border-radius: 30px;
  font-size: 14px;
  font-family: "Lato";
}

.btn-danger,
.btn-danger:hover {
  background: red;
  border-color: red;
  color: white !important;
}

.btn-primary,
.btn-primary:hover {
  background: #f0de00;
  border-color: #f0de00;
  color: #124c5a !important;
}

.btn-import,
.btn-import:hover {
  color: #124c5a !important;
}
</style>
