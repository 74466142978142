<template>
  <div v-if="flightPlanId !== null && dataLoaded">
    <a-row v-if="editingTarget !== null"  style="marginBottom: 20px; margin-left: 1.5%;">
          <a-col
        :md="{ span: 2, offset: 3 }"
        :lg="{ span: 2, offset: 2 }"
        :xl="{ span: 2, offset: 1 }"
        :xxl="{ span: 2, offset: 1 }"
      >
        <a-button @click="switchLayout">
          <a-icon type="border-horizontal" v-if="layout !== 'vertical'" />
          <a-icon type="border-verticle" v-else />
        </a-button>
      </a-col>
    </a-row>
    <a-row type="flex">
      <a-col
        :md="{
          span: layout === 'vertical'  ? (editingTarget ? 20 : 17) : 17,
          offset: layout === 'vertical' ? (editingTarget ? 2 : 3) : 3,
        }"
        :lg="{
          span: layout === 'vertical'  ? (editingTarget ? 20 : 17) : 17,
          offset: layout === 'vertical' ? (editingTarget ? 0 : 3) : 3,
        }"
        :xl="{
          span: layout === 'vertical'  ? (editingTarget ? 10 : 17) : 17,
          offset: layout === 'vertical' ? (editingTarget ? 0 : 3) : 2,
        }"
        :xxl="{
          span: layout === 'vertical' ? (editingTarget ? 10 : 17) : 14,
          offset: layout === 'vertical' ? (editingTarget ? 0 : 3) : 3,
        }"
        :order="layout !== 'vertical' ? 2 : 1"

        class="missionMapList-column"
      >
        <a-row style="marginBotton: 20px;" type="flex">
          <div class="gadgets-table">
            <a-col v-if="isFPManager" :span="2" :offset="3">
              <a-button
                :disabled="disableAddButton"
                type="primary"
                class="btn btn-primary"
                @click="addRow()"
              >
                {{ $t("form.add") }}
              </a-button>
            </a-col>

            <a-col :span="8" :offset="isFPManager ? 5 : 15" align="right">
              <a-input-search
                class="input-search-table"
                :placeholder="$t('common.search')"
                v-model="searchQuery"
              />
            </a-col>
          </div>
        </a-row>

        <a-row>
          <a-table
            :columns="columns"
            :dataSource="resultSearchQuery"
            rowKey="id"
            :pagination="currentPage"
            @change="onTableChanged"
            class="missionMap-table"
          >
            <template slot="name" slot-scope="text, record, index">
              <div key="name">
                <a-form-item
                  v-if="editingTarget && record.id === editingTarget.id"
                  :validate-status="
                    invalidKeys.indexOf('name') === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    style="margin: -5px 0"
                    :value="editingTarget.name"
                    @change="(e) => handleChange(e.target.value, index, 'name')"
                    ref="name"
                    @keyup.enter="() => save(editingTarget)"
                  />
                </a-form-item>
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <template slot="provider" slot-scope="text, record, index">
              <div key="provider">
                <a-form-item v-if="editingTarget && record.id === editingTarget.id"
                  :validate-status="invalidKeys.indexOf('provider') === -1 ? '' : 'error'">
                <a-select :value="defaultLayer" style="width: 140px"
                      @change="(val) => handleChange(val, index, 'provider')">
                      <a-select-opt-group>
                        <span slot="label">Google Map</span>
                        <a-select-option :value="MP.satellite">{{ MP.satellite}}</a-select-option>
                        <a-select-option :value="MP.hybrid">{{ MP.hybrid }}</a-select-option>
                        <a-select-option :value="MP.roadMap">{{ MP.roadMap }}</a-select-option>
                      </a-select-opt-group>
                      <a-select-opt-group>
                        <span slot="label">Open Street Map</span>
                        <a-select-option :value="MP.osm">{{ MP.osm }}</a-select-option>
                      </a-select-opt-group>
                </a-select>
                </a-form-item>
                <template v-else>{{ record.provider }}</template>
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <div class="btn-action">
                <span v-if="editingTarget && record.id === editingTarget.id">
                  <a-row class="btn-form">
                    <a-col :span="10">
                      <a-button
                        class="btn-editing"
                        @click="() => save(editingTarget)"
                        type="link"
                        :title="$t('form.save')"
                      >
                        <a-icon
                          class="btn-icon editing-form"
                          type="check-circle"
                        />
                      </a-button>
                    </a-col>
                    <a-col :span="4"></a-col>
                    <a-col :span="10">
                      <a-button
                        class="btn-editing btn-cancel"
                        @click="() => cancel()"
                        type="link"
                        :title="$t('form.cancel')"
                      >
                        <a-icon
                          class="btn-icon editing-form"
                          type="close-circle"
                        />
                      </a-button>
                    </a-col>
                  </a-row>
                </span>
                <span v-else-if="editingTarget === null">
                  <a-row>
                    <div class="edition-btn">
                      <a-dropdown :trigger="['click']">
                        <a-col :push="8">
                          <a-button
                            ><a-icon style="color: #124c5a" type="more"
                          /></a-button>
                        </a-col>
                        <a-menu slot="overlay">
                          <a-menu-item v-if="isFPManager" @click="edit(record)"
                            ><a-icon class="btn-icon" type="edit" />{{
                              $t("form.edit")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-if="isFPManager"
                            @click="confirmDelete(record)"
                            ><a-icon class="btn-icon" type="delete" />{{
                              $t("form.delete")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-if="!isFPManager"
                            @click="showMissionMap(record)"
                            ><a-icon class="btn-icon" type="global" />{{
                              $t("missionMaps.map")
                            }}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </a-row>
                </span>
              </div>
            </template>
          </a-table>
        </a-row>
      </a-col>

      <a-col 
        :md="{
          span: layout === 'vertical'  ? 17 : 15,
          offset: layout === 'vertical' ? 4 : 3,
        }"
        :lg="{
          span: layout === 'vertical'  ? 17 : 15,
          offset: layout === 'vertical' ? 3 : 2,
        }"
        :xl="{
          span: layout === 'vertical'  ? 8 : 15,
          offset: layout === 'vertical' ? 2 : 2,
        }"
        :xxl="{
          span: layout === 'vertical'  ? 8 : 15,
          offset: layout === 'vertical' ? 1 : 1,
        }"
        :order="layout !== 'vertical' ? 1 : 2"
      >
        <a-row
          v-if="editingTarget || target"
          style="marginBottom: 8px;"
          class="map-content"
        >
          <mission-maps-leaflet :eventBus="eventBus" :missionMap="editingTarget || target"></mission-maps-leaflet>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Table, Button, Row, Col, Form, Icon, Modal } from "ant-design-vue";
import { flightplanTypes } from "../../../store/mutation-types";
import MissionMapsLeaflet from "./MissionMapsLeaflet.vue"
import { isFPManager } from "../../../utils/userAuth";
import { DEFAULT_MISSION_MAP_COORDINATES, MAP_PROVIDER as MP, ZOOM_SETTINGS } from "../../../utils/mapConstantsInfo"

Vue.use(Table);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Icon);
Vue.use(Modal);

let flightPlansUnwatcher = null;
let currentFlightPlanIdUnwatcher = null;

export default {
  name: "missionMapsList",
  components: {
    MissionMapsLeaflet
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    MP() {
      return MP
    },
    columns() {
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "name",
          width: "30%",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: this.$t("missionMaps.provider"),
          dataIndex: "provider",
          width: "30%",
          scopedSlots: { customRender: "provider" },
          sorter: (a, b) => a.name.localeCompare(b.provider),
        },
        {
          dataIndex: "action",
          width: this.editingTarget ? "9%" : "7%",
          scopedSlots: { customRender: "action" },
        },
      ];
      return columns;
    },
    resultSearchQuery() {
      if (this.searchQuery) {
        return this.missionMaps.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split("")
            .every((v) => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.missionMaps;
      }
    },
    disableAddButton() {
      return this.editingTarget !== null;
    },
    ...mapState({
      flightPlanId: (state) => state.flightPlans.currentFlightPlanId,
    }),
  },
  created() {
    flightPlansUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === flightplanTypes.FETCH_FLIGHTPLANS) {
        this.refreshMissionMapsFromStore();
        this.dataLoaded = true;
      }
    });

    currentFlightPlanIdUnwatcher = this.$store.watch(
      (state) => state.flightPlans.currentFlightPlanId,
      () => {
        this.refreshMissionMapsFromStore();
        this.dataLoaded = true;
      }
    );
  },
  mounted() {
    this.eventBus.$on("send-provider-to-form", this.providerSentFromMap)
    this.eventBus.$on('rectangle-coordinates', this.rectangleCoordinates)
    if (this.flightPlanId && this.$store.state.flightPlans.lastFetch === null) {
      this.$store.dispatch("fetchFlightPlans");
    } else {
      this.refreshMissionMapsFromStore();
      this.dataLoaded = true;
    }
    this.layout = this.$store.getters.getUserPreferences().layout
  },
  beforeDestroy() {
    if (currentFlightPlanIdUnwatcher) {
      currentFlightPlanIdUnwatcher();
    }

    if (flightPlansUnwatcher) {
      flightPlansUnwatcher();
    }
  },
  data() {
    return {
      dataLoaded: false,
      editingTarget: null,
      target: null,
      currentPage: {
        defaultCurrent: 1,
        current: 1,
      },
      invalidKeys: [],
      missionMaps: [],
      searchQuery: null,
      layout: null,
      eventBus: new Vue(),
      defaultLayer: MP.satellite
    };
  },
  methods: {
    switchLayout() {
      this.layout = this.layout === "vertical" ? "horizontal" : "vertical";
      this.$store.dispatch("setLayoutPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
              user: {...this.$store.getters.getCurrentUser()},
                            layout: this.layout,
      });
    },
    showMissionMap(missionMap) {
      this.target = missionMap;
    },
    onTableChanged(pagination) {
      if (this.editingTarget !== null) {
        pagination.current = 1;
        this.currentPage.current = pagination.current;
      } else if (
        (this.editingTarget === null) &
        (this.currentPage.current !== pagination.current)
      ) {
        this.currentPage.current = pagination.current;
      }
    },
    onBoundsChanged(bounds) {
      if (this.editingTarget) {
        this.$set(this.editingTarget, "northEastLat", bounds.northEastLat);
        this.$set(this.editingTarget, "northEastLng", bounds.northEastLng);
        this.$set(this.editingTarget, "southWestLat", bounds.southWestLat);
        this.$set(this.editingTarget, "southWestLng", bounds.southWestLng);
      }
    },
    onZoomLevelChanged(zoomLevel) {
      if (this.editingTarget) {
        this.$set(this.editingTarget, "zoomLevel", zoomLevel);
      }
    },
    refreshMissionMapsFromStore() {
      if (this.flightPlanId !== null) {
        this.missionMaps = JSON.parse(
          JSON.stringify(
            this.$store.getters.getFlightPlanById(this.flightPlanId).missionMaps
          )
        );
      }
    },
    handleChange(value, key, column) {
      if (this.editingTarget) {
        this.editingTarget[column] = value
        if (column == "provider") {
          this.defaultLayer = value
          this.eventBus.$emit("send-provider-to-map", value)
        }
      }
    },
    edit(target) {
      this.editingTarget = { ...target }
      this.defaultLayer = target.provider
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store
            .dispatch("deleteMissionMap", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              missionMap: target,
            })
            .then(() => this.refreshMissionMapsFromStore());
        },
        onCancel: () => {},
      });
    },
    save(missionMap) {
      if (Object.hasOwnProperty.call(missionMap, "id")) {
        let isNew = missionMap.id === "new";
        let promise;

        if (isNew) {
          promise = this.$store.dispatch("addMissionMap", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            missionMap,
          });
        } else {
          promise = this.$store.dispatch("updateMissionMap", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            missionMap,
          });
        }

        promise.then(() => {
          this.editingTarget = null;
          this.refreshMissionMapsFromStore()
          this.defaultLayer = MP.satellite
        });
      }
    },
    cancel() {
      this.editingTarget = null;
      this.invalidKeys = [];
      this.defaultLayer = MP.satellite
      let index = this.missionMaps.findIndex(
        (missionMap) => missionMap.id === "new"
      );
      if (index > -1) {
        this.missionMaps.splice(index, 1);
      }
    },
    addRow() {
      var pointBase = [];
      var newMissionMap = {};
      this.$store.getters.getCurrentFlightPlan().points.forEach((id) => {
        if (id.pointType === "basepoint") {
          pointBase = {...id}
          newMissionMap = {
            id: "new",
            name: "",
            northEastLat: pointBase.latitude + 0.001,
            northEastLng: pointBase.longitude + 0.001,
            southWestLat: pointBase.latitude - 0.001,
            southWestLng: pointBase.longitude - 0.001,
            zoomLevel: 16,
            provider: MP.satellite
          };
        }
      })
      if (pointBase.length === 0) {
        newMissionMap = {
          id: "new",
          name: "",
          northEastLat: DEFAULT_MISSION_MAP_COORDINATES.northEastLat,
          northEastLng: DEFAULT_MISSION_MAP_COORDINATES.northEastLng,
          southWestLat: DEFAULT_MISSION_MAP_COORDINATES.southWestLat,
          southWestLng: DEFAULT_MISSION_MAP_COORDINATES.southWestLng,
          zoomLevel: ZOOM_SETTINGS.zoomLevel,
          provider: MP.satellite
        };
      }
      this.currentPage.current = 1;
      this.missionMaps.unshift(newMissionMap);
      this.editingTarget = newMissionMap;
      this.$nextTick(() => {
        this.$refs["name"].focus();
      });
    },
    rectangleCoordinates(northEast, southWest) {
      if (this.editingTarget) {
        this.editingTarget.northEastLat = northEast.lat
        this.editingTarget.northEastLng = northEast.lng
        this.editingTarget.southWestLat = southWest.lat
        this.editingTarget.southWestLng = southWest.lng
      }
    },
    providerSentFromMap(value) {
      this.defaultLayer = value
      this.editingTarget.provider = value
    }
  },
};
</script>

<style scoped lang="less">
.segmentList-container {
  width: 100%;
}

.gadgets-table {
  width: 100%;
  .input-search-table {
    width: 100%;
    margin-left: 15%;
  }
}
.missionMap-table {
  width: 88%;
  margin-left: 13.5%;
  margin-top: 2.5%;
  .ant-input {
    width: 60%;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 1%;
  overflow-wrap: break-word;
}

.btn-action {
  .btn-edit {
    padding-right: 15%;
  }
  .btn-editing {
    align-items: center;
  }
  .btn-form {
    height: 2.5rem;
    width: 3.75rem;
    margin-left: 35%;
    padding-top: 30%;
  }
  .editing-form {
    font-size: 1.65rem;
  }
  .btn-cancel {
    color: red;
  }
  .btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  .btn .btn-icon {
    font-size: 1rem;
  }
  .edition-btn {
    padding-top: 1.5%;
    margin-left: 20%;
  }
}

.map-content {
  width: 95%;
  margin-left: 5%;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .map-container {
    margin-left: 0;
  }
  .map-content {
    width: 100%;
    margin-left: 0;
  }
  .btn {
    margin-left: -15%;
  }
  .btn-form {
    margin-top: -41.5%;
  }
}

@media screen and (min-device-width: 1600px) {
  .map-content {
    width: 96%;
    margin-left: 0;
  }
  .map-container {
    margin-left: 0;
  }
  .btn-form {
    margin-top: -30%;
  }
}

.ant-table {
  display: block;
  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }
    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }
        &:last-of-type {
          padding-bottom: 1rem;
        }
      }
      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;
        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
</style>
