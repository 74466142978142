// Default map center (Eiffel Tower coordinates)
export const DEFAULT_MAP_CENTER = {
    lat: 48.858093,
    lng: 2.294694,
}

// Default mission map coordinates(arround the Eiffel Tower)
export const DEFAULT_MISSION_MAP_COORDINATES = {
    northEastLat: 48.86001,
    northEastLng: 2.298799,
    southWestLat: 48.85668,
    southWestLng: 2.290988
}

export const LAYER_PROVIDERS_URL = {
    "hybrid": "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
    "osm": "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    "roadMap": "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    "satellite": "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
}

export const MAP_PROVIDER = {
    "hybrid": "Hybrid",
    "osm": "OpenStreetMap",
    "roadMap": "RoadMap",
    "satellite": "Satellite"
}

export const SUBDOMAINS = ["mt0", "mt1", "mt2", "mt3"]

export const ZOOM_SETTINGS = {
    "zoomLevel": 16,
    "zoomMax": 20,
    "zoomMin": 16
}
