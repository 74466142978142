<template>
  <l-map ref="map" class="map-class" @ready="handleMapReady" @draw:created="handleDrawCreated"
    @draw:edited="handleDrawEdited"></l-map>
</template>
<script>
import { LMap } from "vue2-leaflet"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import { tilesProviders } from "../../../utils/leaflet/mapFunctions"
import { COLORS } from "../../../utils/colorsInfo"
import { isFPManager } from "../../../utils/userAuth"

let rectangle = null

export default {
  name: "mission-maps-leaflet",
  props: {
    eventBus: Object,
    missionMap: Object
  },
  components: {
    LMap
  },
  data() {
    return {
      currentLayer: null,
      drawControl: null,
      drawControlEdit: null,
      drawnItems: [],
      editingTarget: null,
      providerSelectedfromForm: false,
      mapProviders: null
    }
  },
  mounted() {
    this.eventBus.$on("send-provider-to-map", this.providerSentFromForm)
    this.mapObject = this.$refs.map.mapObject

    let latCenter = (this.missionMap.northEastLat + this.missionMap.southWestLat) / 2
    let lngCenter = (this.missionMap.northEastLng + this.missionMap.southWestLng) / 2

    if (this.editingTarget) {
      let provider = this.editingTarget.provider
      this.mapProviders = tilesProviders(this.mapObject, provider)
      this.currentLayer = this.mapProviders.currentLayer
      this.mapObject.setView([latCenter, lngCenter], this.missionMap.zoomLevel)
    }
  },
  methods: {
    providerSentFromForm(value) {
      let baseMapsLayer = this.mapProviders.baseMaps[value]
      let el = this
      this.mapObject.removeLayer(this.currentLayer)
      baseMapsLayer.addTo(el.mapObject)
      this.currentLayer = baseMapsLayer
      this.providerSelectedfromForm = true
    },
    handleMapReady() {
      //Send Provider selected to form
      if (!this.providerSelectedfromForm) {
          this.mapObject.on('baselayerchange', (e) => {
          this.mapObject.removeLayer(this.currentLayer)
          e.layer.addTo(this.mapObject)
          this.currentLayer = e.layer
          this.eventBus.$emit("send-provider-to-form", e.name)
      })
    }

      //Add toolbar to draw in map
      this.drawnItems = new L.FeatureGroup()
      this.mapObject.addLayer(this.drawnItems)
      this.drawControl = new L.Control.Draw({
        draw: {
          polygon: false,
          marker: false,
          polyline: false,
          circle: false,
          circlemarker: false
          },
          edit: {
            featureGroup: this.drawnItems,
            edit: true,
            remove: false
          }
      })
      this.mapObject.addControl(this.drawControl)
      if (this.editingTarget) {
        let southWest = new L.LatLng(this.missionMap.southWestLat, this.missionMap.southWestLng)
        let northEast = new L.LatLng(this.missionMap.northEastLat, this.missionMap.northEastLng)
        rectangle = this.drawnRectangle(southWest, northEast)
      }
    },
    drawnRectangle(southWest, northEast) {
      return L.rectangle([southWest, northEast], { color: COLORS.freshBlue, weight: 1.5, Opacity: 0.20, draggable: isFPManager() }).addTo(this.drawnItems)
    },
    handleDrawCreated(e) {
      if (rectangle != null) {
        rectangle.removeFrom(this.drawnItems)
      }
      let southWest = e.layer._bounds._southWest
      let northEast = e.layer._bounds._northEast
      rectangle = this.drawnRectangle(southWest, northEast)
      this.eventBus.$emit('rectangle-coordinates', northEast, southWest)
    },
    handleDrawEdited() {
      let bounds = rectangle.getBounds()
      this.eventBus.$emit('rectangle-coordinates', bounds.getNorthEast(), bounds.getSouthWest())
    }
  },
  watch: {
    missionMap: {
      handler(newTarget) {
        this.editingTarget = newTarget
      },
      immediate: true
    }
  },
}
</script>
<style lang="less" scoped>
.map-class {
  width: 1000px;
  height: 400px;

  @media screen and (max-width: 2100px) {
    width: 800px;
    height: 350px;
  }

  @media screen and (max-width: 1700px) {
    width: 630px;
    height: 300px;
    right: 40%;
  }

  @media screen and (max-width: 1344px) {
    right: 50%;
  }
}
</style>
