import axios from "../plugin/axios";
import { addApiPrefix, getErrorMsg, header } from "../helpers";
import { flightplanTypes, geofencePointsTypes } from "../mutation-types";

const state = {
  flightPlans: [],
  lastFetch: null,
  currentFlightPlanId: null,
};

function updateFlightplanObj(flightPlan, obj, objName) {
  let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

  if (fpIdx !== -1) {
    let objIdx = state.flightPlans[fpIdx][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIdx !== -1) {
      state.flightPlans[fpIdx][objName][objIdx] = Object.assign({}, obj);
    }
  }
}

function addFlightplanObj(flightPlan, obj, objName) {
  let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

  if (fpIdx !== -1) {
    state.flightPlans[fpIdx][objName].unshift(obj);
  }
}

function deleteFlightplanObj(flightPlan, obj, objName) {
  let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

  if (fpIdx !== -1) {
    let objIdx = state.flightPlans[fpIdx][objName].findIndex(
      (o) => o.id === obj.id
    );

    if (objIdx !== -1) {
      state.flightPlans[fpIdx][objName].splice(objIdx, 1);
    }
  }
}

function deleteFlightMultipleObj(flightPlan, obj, objName) {
  let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);
  obj.map(obj => {
    if (fpIdx !== -1) {
      let objIdx = state.flightPlans[fpIdx][objName].findIndex(
        (o) => o.id === obj
      );
  
      if (objIdx !== -1) {
        state.flightPlans[fpIdx][objName].splice(objIdx, 1);
      }
    }
  })
}


const mutations = {
  [flightplanTypes.FETCH_FLIGHTPLANS](state, flightPlans) {
    state.flightPlans = [...flightPlans];
    state.lastFetch = new Date();
  },
  [flightplanTypes.UPDATE_FLIGHTPLAN](state, flightPlan) {
    let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

    if (fpIdx !== -1) {
      state.flightPlans[fpIdx].name = flightPlan.name;
    }
  },
  [flightplanTypes.ADD_FLIGHTPLAN](state, flightPlan) {
    state.flightPlans.push(flightPlan);
  },
  [flightplanTypes.DELETE_FLIGHTPLAN](state, flightPlan) {
    let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

    if (fpIdx !== -1) {
      state.flightPlans.splice(fpIdx, 1);
    }
  },
  [flightplanTypes.SET_CURRENT_FLIGHTPLAN_ID](state, flightPlanId) {
    state.currentFlightPlanId = flightPlanId;
  },
  [geofencePointsTypes.UPDATE_GEOFENCE_POINTS](
    state,
    { flightPlan, geofencePoints }
  ) {
    let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

    if (fpIdx !== -1) {
      const fp = state.flightPlans[fpIdx];
      fp.geofencePoints = geofencePoints;
    }
  },
  [geofencePointsTypes.DELETE_GEOFENCE_POINTS](state, { flightPlan }) {
    let fpIdx = state.flightPlans.findIndex((f) => f.id === flightPlan.id);

    if (fpIdx !== -1) {
      state.flightPlans[fpIdx].geofencePoints = [];
    }
  },
};

const actions = {
  fetchFlightPlans({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(addApiPrefix("/flightplans"), header())
        .then((resp) => {
          commit(
            flightplanTypes.FETCH_FLIGHTPLANS,
            resp.data.payload.flightPlans
          );
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateFlightPlan({ commit }, flightPlan) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          addApiPrefix(`/flightplans/${flightPlan.id}`),
          {
            name: flightPlan.name,
          },
          header()
        )
        .then((resp) => {
          flightPlan = resp.data.payload.flightPlan;
          commit(flightplanTypes.UPDATE_FLIGHTPLAN, flightPlan);
          resolve(flightPlan)
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  addFlightPlan({ commit }, flightPlan) {
    return new Promise((resolve, reject) => {
      flightPlan = Object.assign({}, flightPlan);
      delete flightPlan.id;

      axios
        .post(addApiPrefix(`/flightplans`), flightPlan, header())
        .then((resp) => {
          flightPlan = resp.data.payload.flightPlan;
          commit(flightplanTypes.ADD_FLIGHTPLAN, flightPlan);
          resolve(flightPlan)
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  deleteFlightPlan({ commit }, flightPlan) {
    return new Promise((resolve, reject) => {
      axios
        .delete(addApiPrefix(`/flightplans/${flightPlan.id}`), header())
        .then(() => {
          commit(flightplanTypes.DELETE_FLIGHTPLAN, flightPlan);
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
  setCurrentFlightPlanId({ commit }, currentFlightPlanId) {
    commit(flightplanTypes.SET_CURRENT_FLIGHTPLAN_ID, currentFlightPlanId);
  },
  updateGeofencePoints(
    { commit },
    { flightPlan, geofencePoints, sync = true }
  ) {
    return new Promise((resolve, reject) => {
      if (sync) {
        axios
          .put(
            addApiPrefix(`/flightplans/${flightPlan.id}/geofencepoints`),
            {
              geofencePoints,
            },
            header()
          )
          .then(() => {
            commit(geofencePointsTypes.UPDATE_GEOFENCE_POINTS, {
              flightPlan,
              geofencePoints,
            });
            resolve();
          })
          .catch((err) => {
            reject(Error(getErrorMsg(err)));
          });
      } else {
        commit(geofencePointsTypes.UPDATE_GEOFENCE_POINTS, {
          flightPlan,
          geofencePoints,
        });
        resolve();
      }
    });
  },
  deleteGeofencePoints({ commit }, { flightPlan }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          addApiPrefix(`/flightplans/${flightPlan.id}/geofencepoints`),
          header()
        )
        .then(() => {
          commit(geofencePointsTypes.DELETE_GEOFENCE_POINTS, { flightPlan });
          resolve();
        })
        .catch((err) => {
          reject(Error(getErrorMsg(err)));
        });
    });
  },
};

const getters = {
  getFlightPlanById: (state) => (id) => {
    return state.flightPlans.find((f) => f.id === id);
  },
  getCurrentFlightPlan: (state) => () => {
    const currentId = state.currentFlightPlanId;
    return state.flightPlans.find((f) => f.id === currentId);
  },
  getCurrentPointById: (state) => (currentFlight, id) => {
    return currentFlight.points.find(obj => obj.id === id)
  },
  getCurrentSegmentById: (state) => (currentFlight, id) => {
    return currentFlight.segments.find(obj => obj.id === id)
  },
};

export { addFlightplanObj, deleteFlightplanObj, updateFlightplanObj, deleteFlightMultipleObj };

export default {
  state,
  mutations,
  actions,
  getters,
};
